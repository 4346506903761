











import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class Countdown extends Vue {

    @Prop()
    deadline!: Date

    timeUntil = 0

    get days() {
        return Math.floor(this.timeUntil / (24 * 60 * 60))
    }

    get hours() {
        return Math.floor((this.timeUntil % (24 * 60 * 60)) / (60 * 60))
    }

    get minutes() {
        return Math.floor((this.timeUntil % 3600) / 60)
    }

    get seconds() {
        return Math.floor(this.timeUntil % 60)
    }

    mounted() {
        setInterval(() => {
            this.updateTime()
        }, 1000)
        this.updateTime()
    }

    updateTime() {
        this.timeUntil = (this.deadline.getTime()  - new Date().getTime()) / 1000
    }

}

