






















































































































































import {Component, Vue} from 'vue-property-decorator';
import Glitch from '../components/effects/Glitch.vue';
import Countdown from '../components/Countdown.vue';
import axios from 'axios';
import {api} from '@/route';

@Component({
  components: {Glitch, Countdown},
  async beforeRouteEnter(to, from, next) {
    const contest = (await axios.get(api('/contest/current'))).data;
    if (contest.rounds.length) {
      return next('/vbr3/submit');
    }
    next();
  }
})
export default class ContestSplash extends Vue {

  signup() {
    window.location.href = '/api/signup';
  }

  deadline = new Date(1652994000 * 1000);

  deadlinePassed = true

  updateDeadline() {
    this.deadlinePassed =  new Date() > this.deadline
  }

  mounted() {
    setInterval(() => this.updateDeadline(), 1000)
    this.updateDeadline()
  }

  judges = [
    {
      name: 'Gillstar',
      id: 7948210,
      avatar: 'https://a.ppy.sh/7948210?1619992128.jpeg'
    },
    {
      name: 'Sharu',
      id: 5597639,
      avatar: 'https://a.ppy.sh/5597639?1515244823.png'
    },
    {
      name: 'Myxo',
      id: 2202645,
      avatar: 'https://a.ppy.sh/2202645?1637319158.jpeg'
    },
    {
      name: 'Mazzerin',
      id: 2942381,
      avatar: 'https://a.ppy.sh/2942381?1509229790.jpg'
    },
    {
      name: 'zhuxiaoyan',
      id: 5129592,
      avatar: 'https://a.ppy.sh/5129592?1650752607.jpeg',
    },
    {
      name: 'arkisol',
      id: 9331411,
      avatar: 'https://a.ppy.sh/9331411?1642204438.png',
    },
    {
      name: 'Lulu-',
      id: 4201715,
      avatar: 'https://a.ppy.sh/4201715?1641039505.png',
    },
  ];

}
